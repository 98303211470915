<template>
  <InfiniteScroll
    :loading="loading"
    @loadMore="loadMore"
    :hasMoreToLoad="nextToken ? true : false"
  >
    <div class="container md:hidden">
      <LogoHeader colorLogo="blue" :showBackButton="true" />
    </div>
    <div class="px-4 md:mt-8">
      <h1 class="capitalize" v-if="displayName">
        {{ title }}
      </h1>
      <div class="md:grid md:grid-cols-2 md:gap-4 md:grid-flow-row">
        <template v-if="!localTeams">
          <TeamCard
            v-for="(n, index) in 5"
            :key="index"
            :team="{}"
            :isPlaceholder="true"
          />
        </template>
        <template v-else>
          <TeamCard
            class=""
            v-for="team in localTeams"
            :key="team.slug"
            :team="team"
          />
        </template>
      </div>
      <!--
          <div class="other-card w-full rounded-md mt-4 px-4 py-6 bg-gray-200">
            <h3 class="text-blue-900">
              {{ $t("message.are-you-a-social-activist") }}
            </h3>
            <div class="font-body text-blue-900 mt-4">
              {{ $t("message.social-activist-info") }}
            </div>
            <button
              class="btn btn-default w-48 mt-10"
            >
              {{ $t("message.apply-now") }}
            </button>
          </div>
        -->
    </div>
  </InfiniteScroll>
</template>
<script>
import TeamCard from "@/components/shared/TeamCard.vue";
import LogoHeader from "@/components/shared/LogoHeader.vue";
import InfiniteScroll from "@/components/shared/InfiniteScroll.vue";
import { GET_TEAMS_FROM_PROFILE } from "@/graphql/queries/post/postsQueries.js";
import { GET_OTHER_PROFILE } from "@/graphql/queries/user/userQueries.js";

export default {
  name: "ProfileTeamsPage",
  data() {
    return {
      skipOtherNameQuery: false,
      nextToken: null,
      loading: true,
      localTeams: [],
      profileId: null,
      displayName: null
    };
  },
  components: {
    TeamCard,
    LogoHeader,
    InfiniteScroll
  },
  computed: {
    isViewTeams() {
      return this.$route.meta.view === "teams";
    },
    title() {
      if (this.isViewTeams) {
        return this.$t("message.teams-profile", [this.displayName]);
      } else {
        return this.$t("message.captaincies-profile", [this.displayName]);
      }
    }
  },
  apollo: {
    profile: {
      query: GET_OTHER_PROFILE,
      update(data) {
        this.localTeams = [];
        this.displayName = data.getProfile.displayName;
        if (this.isViewTeams) {
          this.localTeams.push(...data.getProfile.teamMemberships.teams);
        } else {
          this.localTeams.push(...data.getProfile.teamCaptaincies.teams);
        }
        this.loading = false;
        this.profileId = data.getProfile.id;
        this.nextToken = data.getProfile.teamMemberships.nextToken;
        return data.getProfile;
      },
      variables() {
        return {
          screenName: this.$route.params.screenName
        };
      },
      // can be improved by pushing only new items on the response and fetchPolicy cache-and-network
      fetchPolicy: "network-only"
    },
    teams: {
      query: GET_TEAMS_FROM_PROFILE,
      update(data) {
        this.localTeams.push(...data.getProfileTeamMemberships.teams);
        this.$nextTick(() => {
          this.loading = false;
          this.nextToken = data.getProfileTeamMemberships.nextToken;
        });
        return data.getProfileTeamMemberships;
      },
      //test if this property is needed
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          nextToken: this.nextToken,
          profileId: this.profileId,
          limit: 6
        };
      },
      skip() {
        return this.loading === false || !this.profileId || !this.nextToken;
      }
    }
  },
  methods: {
    loadMore() {
      if (!this.loading) {
        this.loading = true;
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
.other-card {
  height: 302px;
}
</style>
