<template>
  <div id="scroll-div" class="w-full flex flex-col max-h-full overflow-y-auto">
    <slot id="items-list" />
    <div
      v-if="loading && hasMoreToLoad"
      class="w-full flex justify-center mt-4"
    >
      <div class="w-14 h-14 relative flex justify-center items-center">
        <AppleSpinner />
      </div>
    </div>
  </div>
</template>
<script>
import AppleSpinner from "@/components/shared/AppleSpinner.vue";

export default {
  components: {
    AppleSpinner
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    hasMoreToLoad: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    loading(val) {
      if (!val) {
        this.verifyIfScreenIsfilled();
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scroll, false);
    window.addEventListener("resize", this.scroll, false);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll, false);
    window.removeEventListener("resize", this.scroll, false);
  },
  methods: {
    scroll() {
      const scrollDiv = document.getElementById("scroll-div");
      const positionFromTop = scrollDiv.offsetTop || 0;
      this.log(["scrollable position from top", positionFromTop]);
      this.log(["screen height - window.innerHeight", window.innerHeight]);
      this.log([
        "current scroll position from top",
        document.documentElement.scrollTop + window.innerHeight
      ]);
      this.log(["scrollable div height", scrollDiv.scrollHeight]);
      if (
        document.documentElement.scrollTop + window.innerHeight >=
          scrollDiv.scrollHeight - (positionFromTop + 50) &&
        !this.loading &&
        this.hasMoreToLoad
      ) {
        this.log(
          [
            "******************** need more! infinite scroll *******************"
          ],
          true
        );
        this.$emit("loadMore");
      }
      this.log(["----------------------------------------------------------"]);
    },
    verifyIfScreenIsfilled() {
      const scrollDiv = document.getElementById("scroll-div");
      const positionFromTop = scrollDiv.offsetTop || 0;

      this.log(["div scrollable -> positionFromTop", positionFromTop]);
      this.log([
        "div scrollable div height -> scrollDiv.offsetHeight",
        scrollDiv.offsetHeight
      ]);
      this.log(["window.innerHeight ->", window.innerHeight]);
      if (
        scrollDiv.offsetHeight - positionFromTop < window.innerHeight &&
        this.hasMoreToLoad
      ) {
        this.log(["verifyIfScreenIsfilled asking for more"], true);
        this.$emit("loadMore");
      } else {
        this.log([
          "not asking for more and this.hasMoreToLoad = " + this.hasMoreToLoad
        ]);
      }
    },
    // eslint-disable-next-line no-unused-vars
    log(msg, warn = false) {
      if (warn) {
        //console.warn(...msg);
        return;
      }
      //console.log(...msg);
    }
  }
};
</script>
