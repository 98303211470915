import gql from "graphql-tag";

export const GET_SINGLE_POST = gql`
  query getSinglePost($postId: ID!) {
    getPost(postId: $postId) {
      id
      imageUrl
      teamId
      creator {
        id
        displayName
        screenName
        profileImageUrl
      }
      publishState
      text
      textColor
      backgroundColor
      videoUrl
      updatedAt
      createdAt
      textPlacement {
        angle
        x
        y
      }
    }
  }
`;

export const GET_POSTS_FROM_ID = gql`
  query getPosts($id: ID!, $nextToken: String) {
    getPosts(postedTo: $id, limit: 6, nextToken: $nextToken) {
      posts {
        backgroundColor
        id
        publishState
        imageUrl
        textColor
        videoUrl
        text
        creator {
          id
          displayName
          screenName
          profileImageUrl
        }
        teamId
        textPlacement {
          angle
          x
          y
        }
      }
      nextToken
    }
  }
`;

export const GET_TEAMS_FROM_PROFILE = gql`
  query getProfileTeamMemberships(
    $profileId: ID!
    $nextToken: String
    $limit: Int!
  ) {
    getProfileTeamMemberships(
      limit: $limit
      profileId: $profileId
      nextToken: $nextToken
    ) {
      nextToken
      teams {
        id
        imageUrl
        name
        slug
        teamCaptains {
          profiles {
            displayName
            profileImageUrl
            id
            screenName
          }
        }
      }
    }
  }
`;

export const GET_POSTS_FROM_PROFILE = gql`
  query getProfilePostsByScreenName($screenName: String!) {
    getProfile(screenName: $screenName) {
      id
      displayName
      posts {
        posts {
          backgroundColor
          id
          imageUrl
          textColor
          videoUrl
          publishState
          text
          creator {
            id
            displayName
            screenName
            profileImageUrl
          }
          teamId
          textPlacement {
            angle
            x
            y
          }
        }
        nextToken
      }
    }
  }
`;

export const GET_POSTS_FROM_TEAM = gql`
  query getTeamPostsByScreenName($slug: String!) {
    getTeam(slug: $slug) {
      id
      name
      teamCaptains {
        profiles {
          id
          screenName
          displayName
        }
      }
      posts {
        nextToken
        posts {
          id
          imageUrl
          textColor
          text
          videoUrl
          teamId
          backgroundColor
          publishState
          textPlacement {
            angle
            x
            y
          }
        }
      }
    }
  }
`;

export const GET_TIMELINE_POSTS = gql`
  query GetMyTimeline($limit: Int!, $nextToken: String) {
    getMyTimeline(limit: $limit, nextToken: $nextToken) {
      nextToken
      posts {
        id
        creator {
          id
          displayName
          screenName
          profileImageUrl
        }
        textColor
        text
        updatedAt
        videoUrl
        imageUrl
        backgroundColor
        publishState
        textPlacement {
          angle
          x
          y
        }
      }
    }
  }
`;
