<template>
  <div>
    <router-link
      v-if="!isPlaceholder"
      :to="{ name: 'TeamPage', params: { slug: team.slug } }"
      :style="`background-image: url('${team.imageUrl}')`"
      class="team-card bg-cover bg-no-repeat w-full overflow-hidden rounded-md my-4"
    >
      <div class="bg h-full w-full px-4 py-6 flex flex-col relative">
        <div v-if="team.teamCaptains" class="absolute flex flex-row right-6">
          <template v-for="captain in team.teamCaptains.profiles">
            <div
              v-if="captain.profileImageUrl"
              class="rounded-full w-12 h-12 overflow-hidden ml-4 border-2 border-gray-50"
              :key="captain.id"
            >
              <img
                :src="captain.profileImageUrl"
                :alt="captain.name"
                class="w-full"
              />
            </div>
          </template>
        </div>
        <div
          v-if="team.status == 'coming_soon'"
          class="absolute uppercase bg-pink-300 rounded-full h-20 w-20 flex justify-center items-center text-center right-4 top-4"
        >
          <h5 class="text-white">Coming soon</h5>
        </div>
        <div class="flex justify-center flex-col items-center mt-auto mb-8">
          <h1 class="text-white text-center mb-2">
            {{ team.name }}
          </h1>
          <h5 v-if="captainsText" class="capitalize text-white">
            {{ captainsText }}
          </h5>
        </div>
      </div>
    </router-link>
    <div
      class="team-card bg-cover bg-no-repeat w-full overflow-hidden rounded-md my-4 bg-gray-placeholder"
      v-else
    />
  </div>
</template>
<script>
export default {
  props: {
    team: {
      type: Object,
      required: true
    },
    isPlaceholder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    captainsText() {
      if (!this.team.teamCaptains?.profiles.length) return "";

      const captains = this.team.teamCaptains.profiles.map(
        captain => captain.displayName
      );

      return `${
        captains.length > 1
          ? this.$t("message.captains")
          : this.$t("message.captain")
      }: ${captains.join(", ")}`;
    }
  }
};
</script>
<style lang="postcss" scoped>
.team-card {
  height: 406px;

  .bg {
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 0%,
      rgb(0 0 0 / 94%) 100%
    );
  }

  h6 {
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
  }

  h6::before,
  h6::after {
    content: "";
    flex-grow: 1;
    background: white;
    height: 2px;
    width: 16px;
    font-size: 0;
    line-height: 0px;
    margin: 0 8px;
  }
}
</style>
